import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGet } from '../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { setProductInfo } from '../../../../store/products';
import EachProductInfo from '../../../layouts/eachProductInfo';
import { Grid } from '@mui/material';
import ClientRentObjects from '../../../layouts/clientRentObjects';
import errorHandler from '../../../layouts/errorHandler';
import { HeaderPage } from '../../../common/headerPage/HeaderPage';
import { Spinner } from '../../../common/spinner/Spinner';
import ProductAdditionalAgreement from '../../../layouts/productAdditionalAgreement';

const EachProduct = () => {
  const { productId } = useParams();
  const [isFetch, setIsFetch] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productInfo = useSelector((state) => state.products.productInfo);

  const getProduct = () => {
    apiGet({
      url: `/product/view?id=${productId}`,
    }).then((res) => {
      if (res.error) {
        if (res.status === 404) {
          navigate('/404');
        }
        return errorHandler(res.data);
      }
      dispatch(setProductInfo(res.data));
      setIsFetch(true);
    });
  };

  useEffect(getProduct, [productId]);

  if (!isFetch) {
    return <Spinner />;
  }

  return (
    <div>
      <HeaderPage title={`Товар ${productInfo?.name}`} />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          lg={6}
        >
          <EachProductInfo />
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
        >
          <ProductAdditionalAgreement onRefetch={getProduct} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EachProduct;
