import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useState, forwardRef, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { apiGet, apiPost } from '../../../../api';
import { Drawer, ListItem, Pagination, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import errorHandler from '../../../layouts/errorHandler';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../confirm';
import { setCategoryProp, setShowingDialog } from '../../../../store/app';
import EditIcon from '@mui/icons-material/Edit';
import TreeView from '@mui/lab/TreeView';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Fade
      ref={ref}
      {...props}
    />
  );
});

const DialogAppSetting = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  // Меню для настройки
  const [editIsOpen, setEditIsOpen] = useState({ id: null, open: false });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOptions, setIsOpenOptions] = useState(false);

  // States for Input
  const [optionName, setOptionName] = useState('');
  const [optionPrice, setOptionPrice] = useState('');
  const [newCategory, setNewCategory] = useState('');

  // Список регионов / городов
  const [optionList, setOptionList] = useState([]);

  // Список категорий
  const [categoryList, setCategoryList] = useState([]);
  const [productTypeName, setProductTypeName] = useState('');
  const [productTypeEditIsOpen, setProductTypeEditIsOpen] = useState({
    id: null,
    open: false,
  });

  const [optionId, setOptionId] = useState('');

  const [openAnchor, setOpenAnchor] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  // const [categoryNameNode, setCategoryNameNode] = useState(null)

  // Drawer Типы категорий
  const [isOpenTypesCategory, setIsOpenTypesCategory] = useState(false);

  // Список типов категорий - бесконечно вложенный список
  const [flowTypeList, setFlowTypeList] = useState([]);
  // Модальное окно для категорий
  const [categoryDialogIsOpen, setCategoryDialogIsOpen] = useState(false);
  const [buttonTitleDialog, setButtonTitleDialog] = useState('');
  const [categoryEvent, setCategoryEvent] = useState('');
  const [categoryNameNode, setCategoryNameNode] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [idOfTypeList, setIdOfTypeList] = useState('');

  // Редактирование типов категорий
  const [isEditProductType, setIsEditProductType] = useState({
    id: null,
    open: false,
  });
  const [typeEditCategory, setTypeEditCategory] = useState('');

  const [productTypeList, setProductTypeList] = useState({
    id: null,
    list: [],
    open: false,
  });
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  //Start --- Пагинация
  // Для меню добавить город/регион для доставки
  const [pageCities, setPageCities] = useState(1);
  const [citiesLastPage, setCitiesLastPage] = useState(1);

  // Для меню категории товаров и их типы
  const [categoryPage, setCategoryPage] = useState(1);
  const [categoryLastPage, setCategoryLastPage] = useState(1);

  //End---- Пагинация

  const editIsOpenHandler = (id, open) => {
    if (editIsOpen.id !== id) {
      setEditIsOpen((prev) => ({ id: prev.id, open: false }));
      return setEditIsOpen({ id, open: true });
    }
    setEditIsOpen({ id, open });
    setOptionName('');
  };

  const productTypeEditIsOpenHandler = (id, open) => {
    if (productTypeEditIsOpen.id !== id) {
      setProductTypeEditIsOpen((prev) => ({ id: prev.id, open: false }));
      return setProductTypeEditIsOpen({ id, open: true });
    }
    setProductTypeEditIsOpen({ id, open });
  };

  const toggleDrawer = (isOpen) => {
    setProductTypeName('');
    setNewCategory('');
    setTypeEditCategory('');
    setIsEditProductType({ id: null, open: false });
    setOpenAnchor(isOpen);
    dispatch(setCategoryProp(false));
  };

  const closeConfirmDialog = () => {
    setShowConfirm(false);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const deleteOptionItem = (optId) => {
    setOptionId(optId);
    setShowConfirm(true);
  };

  const categoryDelete = (catId) => {
    setCategoryId(catId);
    dispatch(setCategoryProp(true));

    // setShowConfirm(true)
    dispatch(setShowingDialog('deleteCategory'));
  };

  // Просмотреть доп свойства к доп услугам
  const getExtraOptions = () => {
    apiGet({
      url: `/additional-properties/index?page=${pageCities}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setOptionList(res.data.models);
      setCitiesLastPage(res.data.lastPage);
    });
  };

  const optionConfirmHandleClick = () => {
    apiGet({
      url: `/additional-properties/delete?id=${optionId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Удалено');
      getExtraOptions();
      dispatch(setCategoryProp(true));
    });
    closeConfirmDialog(false);
  };

  // Получение списка категорий
  const getCategoryTypeIndex = () => {
    apiGet({
      url: '/category-type/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setCategoryList(res.data.models);
      setCategoryLastPage(res.data.lastPage);
    });
  };

  const categoryConfirmHandleClick = () => {
    // Удаление категории
    apiGet({
      url: `/category-type/delete?id=${categoryId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      getCategoryTypeIndex();

      toast.success('Успешно удалено');
    });
  };

  useEffect(() => {
    // Просмотреть доп свойства к доп услугам
    getExtraOptions();
  }, [pageCities]);

  useEffect(() => {
    // Получение списка категорий
    getCategoryTypeIndex();
  }, [categoryPage]);

  useEffect(() => {
    // Получение типов категорий
    getFlowTypeList();
  }, []);

  const addNewOption = () => {
    let data = {};
    data.name = optionName;
    data.price = optionPrice;

    apiPost({
      url: '/additional-properties/create?id=1',
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Просмотреть доп свойства к доп услугам
      getExtraOptions();
      setOptionName('');
      setOptionPrice('');
      dispatch(setCategoryProp(true));
    });
  };

  const addCategoryOnChange = (e) => {
    setNewCategory(e.target.value);
  };

  const addNewPriceOptionOnChange = (e) => {
    setOptionPrice(e.target.value);
  };

  const addNewNameOptionOnChange = (e) => {
    setOptionName(e.target.value);
  };

  const createNewCategory = () => {
    let data = {};
    data.name = newCategory;
    apiPost({
      url: `/category-type/create`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      // Получение списка категорий
      getCategoryTypeIndex();
      setNewCategory('');
      dispatch(setCategoryProp(true));
    });
  };

  const updateOptionItem = (optId) => {
    let data = {};
    data.name = optionName;
    data.price = optionPrice;
    apiPost({
      url: `/additional-properties/update?id=${optId}`,
      postData: data,
    }).then((res) => {
      console.log(res);
      if (res.error) return errorHandler(res.data);
      toast.success('Обновлено');

      dispatch(setCategoryProp(true));
      // Просмотреть доп свойства к доп услугам
      getExtraOptions();
    });
  };

  const getFlowTypeList = () => {
    apiGet({
      url: '/flow-type/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setFlowTypeList(res.data);
    });
  };

  const openCategoryDialogHandler = () => {
    setCategoryDialogIsOpen(true);
  };

  const categoryEventHandler = (id, event, name) => {
    openCategoryDialogHandler();
    setCategoryId(id);
    setCategoryNameNode(name);
    setCategoryEvent(event);

    if (event === 'Добавить категорию') setButtonTitleDialog('Добавить');
    if (event === 'Редактировать категорию') setButtonTitleDialog('Обновить');
    if (event === 'Удалить категорию') setButtonTitleDialog('Удалить');
  };

  const closeCategoryDialogHandler = () => {
    setCategoryDialogIsOpen(false);
    dispatch(setCategoryProp(false));
  };

  const createNewCategoryType = () => {
    const data = {};
    let url = '';
    data.name = categoryName;

    if (categoryEvent === 'Удалить категорию') {
      apiGet({
        url: `/flow-type/delete?id=${categoryId}`,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        getFlowTypeList();
        toast.success('Категория удалена');
        dispatch(setCategoryProp(true));
      });
    }

    if (categoryEvent === 'Редактировать категорию')
      url = `/flow-type/update?id=${categoryId}`;
    if (categoryEvent === 'Добавить категорию')
      url = `/flow-type/create?id=${categoryId}`;

    if (url !== '') {
      apiPost({
        url: url,
        postData: data,
      }).then((res) => {
        if (res.error) return errorHandler(res.data);
        getFlowTypeList();
        toast.success('Успешно');
        dispatch(setCategoryProp(true));
      });
    }
    closeCategoryDialogHandler();
  };

  const addNewProductType = (id) => {
    const data = {};
    data.name = productTypeName;

    apiPost({
      url: `/product-type/create?id=${id}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Тип создан');
      setProductTypeEditIsOpen({ id: null, open: false });
      showProductTypeList(id);
      getCategoryTypeIndex();
      dispatch(setCategoryProp(true));
    });
  };

  // show the type of product list here
  const showProductTypeList = (id) => {
    apiGet({
      url: `/product-type/index?category_type_id=${id}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);

      setProductTypeList({ id, list: res.data.models });
    });
  };

  const confirmProductTypeDelete = () => {
    apiGet({
      url: `/product-type/delete?id=${idOfTypeList}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Тип удален');
      getCategoryTypeIndex();
      dispatch(setCategoryProp(true));
    });
    setOpenConfirmDeleteDialog(false);
  };

  const productTypeDelete = (id) => {
    setOpenConfirmDeleteDialog(true);
    setIdOfTypeList(id);
  };
  const closeDrawerOptions = () => {
    setOptionName('');
    setOptionPrice('');
    setEditIsOpen({ open: false });
    setIsOpenOptions(false);
    dispatch(setCategoryProp(false));
  };

  const openEditProductType = (id, open) => {
    if (isEditProductType.id !== id) {
      setIsEditProductType((prev) => ({ id: prev.id, open: false }));

      return setIsEditProductType({ id, open: true });
    }
    setIsEditProductType({ id, open });
  };

  const saveUpdateTypeCategory = (id) => {
    const data = {};
    data.name = typeEditCategory;

    apiPost({
      url: `/product-type/update?id=${id}`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Обновлено');
      getCategoryTypeIndex();
      setIsEditProductType({ id: null, open: false });
      dispatch(setCategoryProp(true));
    });
  };

  const categoryNameOnChange = (e) => {
    setCategoryName(e.target.value);
  };

  // const showMeMore = () => {
  //     dispatch(setCategoryProp(true))
  //     console.log(isCategoryChange)
  // }

  //Start ------- Категории расходов - открываются внутри Drawer
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id + ''}
      label={
        nodes.name === 'root' ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ borderRadius: 2, height: 1, p: 0 }}
              onClick={() => categoryEventHandler(1, 'Добавить категорию')}
            >
              <AddBoxIcon />
            </IconButton>
            <Typography
              variant='h6'
              sx={{ mb: 0, ml: 1 }}
            >
              Категории
            </Typography>
          </Box>
        ) : (
          nodes.name
        )
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => (
            <Box
              key={node.id}
              sx={{ display: 'flex' }}
            >
              {node.children !== null || node?.children?.length !== 0 ? (
                <>
                  <Box
                    sx={{ height: 1, p: 0, m: 0 }}
                    component={'span'}
                    title='Добавить'
                  >
                    <IconButton
                      sx={{ borderRadius: 2, height: 1, p: 0 }}
                      onClick={() =>
                        categoryEventHandler(node.id, 'Добавить категорию')
                      }
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{ height: 1, p: 0, m: 0 }}
                    component={'span'}
                    title='Редактировать'
                  >
                    <IconButton
                      sx={{ borderRadius: 2, height: 1, p: 0 }}
                      onClick={() =>
                        categoryEventHandler(
                          node.id,
                          'Редактировать категорию',
                          node.name,
                        )
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>

                  <IconButton
                    color={'abortButton'}
                    sx={{ borderRadius: 2, height: 1, p: 0 }}
                    onClick={() =>
                      categoryEventHandler(node.id, 'Удалить категорию')
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
              {renderTree(node)}
            </Box>
          ))
        : null}
    </TreeItem>
  );
  //End ------- Категории расходов - открываются внутри Drawer

  return (
    <div>
      <MenuItem
        sx={{ pl: 4 }}
        color={'secondary'}
        onClick={() => setIsOpen(true)}
      >
        <SettingsIcon
          color='primary'
          sx={{ mr: 1 }}
        />
        Настройки системы
      </MenuItem>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth='sm'
        scroll='body'
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Настройки
            </Typography>
          </Box>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Категории товаров и их типы</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => toggleDrawer(true)}
                >
                  Категории и типы товаров
                </Button>
                <Drawer
                  sx={{ zIndex: 9999 }}
                  anchor={'left'}
                  open={openAnchor}
                  onClose={() => toggleDrawer(false)}
                >
                  <Box sx={{ width: 270 }}>
                    <List>
                      <ListItem sx={{ display: 'block' }}>
                        <TextField
                          onKeyDown={(e) => e.stopPropagation()}
                          onChange={addCategoryOnChange}
                          label={'Категория'}
                          value={newCategory || ''}
                        />
                        <Button
                          onClick={createNewCategory}
                          variant={'contained'}
                          sx={{ mt: 1 }}
                        >
                          Добавить
                        </Button>
                      </ListItem>
                      {categoryList.map((item) => (
                        <Card
                          raised
                          key={item.id}
                          sx={{ my: 2, mx: 1 }}
                        >
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <ListItemText primary={item.name} />
                            </AccordionSummary>

                            <AccordionDetails>
                              {item?.productType.map((item) => (
                                <Card
                                  key={item.id}
                                  sx={{ m: 1 }}
                                >
                                  <Box sx={{ display: 'flex', m: 1 }}>
                                    <ListItem>{item.name}</ListItem>

                                    <Tooltip title={'sdsd'}>
                                      <IconButton
                                        onClick={() =>
                                          openEditProductType(
                                            item.id,
                                            !isEditProductType.open,
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>

                                    <IconButton
                                      color={'abortButton'}
                                      onClick={() => productTypeDelete(item.id)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>

                                  {isEditProductType.id === item.id &&
                                  isEditProductType.open ? (
                                    <Box sx={{ display: 'block', m: 1 }}>
                                      <TextField
                                        onKeyDown={(e) => e.stopPropagation()}
                                        onChange={(e) =>
                                          setTypeEditCategory(e.target.value)
                                        }
                                        label={'Название'}
                                      />
                                      <Button
                                        onClick={() =>
                                          saveUpdateTypeCategory(item.id)
                                        }
                                        sx={{ mt: 1 }}
                                        variant={'contained'}
                                      >
                                        Обновить
                                      </Button>
                                    </Box>
                                  ) : (
                                    <></>
                                  )}
                                </Card>
                              ))}
                              {productTypeEditIsOpen.id === item.id &&
                              productTypeEditIsOpen.open ? (
                                <Box sx={{ m: 1 }}>
                                  <TextField
                                    onKeyDown={(e) => e.stopPropagation()}
                                    onChange={(e) =>
                                      setProductTypeName(e.target.value)
                                    }
                                    label={'Имя'}
                                  />
                                  <Button
                                    sx={{ mt: 1 }}
                                    variant={'contained'}
                                    onClick={() => addNewProductType(item.id)}
                                  >
                                    Добавить
                                  </Button>
                                </Box>
                              ) : (
                                <></>
                              )}
                              <Box
                                sx={{ display: 'flex', justifyContent: 'end' }}
                              >
                                <Box
                                  sx={{ height: 1, p: 0, m: 0 }}
                                  component={'span'}
                                  title='Добавить подтип'
                                >
                                  <IconButton
                                    onClick={() =>
                                      productTypeEditIsOpenHandler(
                                        item.id,
                                        !productTypeEditIsOpen.open,
                                      )
                                    }
                                  >
                                    <AddBoxIcon />
                                  </IconButton>
                                </Box>

                                <Box
                                  sx={{ height: 1, p: 0, m: 0 }}
                                  component={'span'}
                                  title='Удалить категорию'
                                >
                                  <IconButton
                                    color={'abortButton'}
                                    onClick={() => categoryDelete(item.id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Card>
                      ))}
                    </List>

                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', my: 2 }}
                    >
                      <Pagination
                        page={categoryPage}
                        count={categoryLastPage}
                        onChange={(e, value) => setCategoryPage(value)}
                        siblingCount={!matches ? 0 : 1}
                        size={!matches ? 'small' : 'medium'}
                        variant='outlined'
                        shape='rounded'
                      />
                    </Box>
                  </Box>
                </Drawer>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Добавить регион/город для доставки</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => setIsOpenOptions(true)}
                >
                  Список городов для доставки
                </Button>
                <Drawer
                  sx={{ zIndex: '99999' }}
                  anchor={'left'}
                  open={isOpenOptions}
                  onClose={closeDrawerOptions}
                >
                  <Box sx={{ width: 270 }}>
                    <List>
                      <ListItem sx={{ display: 'block' }}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Создать</Typography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <TextField
                              label={'Название'}
                              sx={{ my: 2 }}
                              required={true}
                              onKeyDown={(e) => e.stopPropagation()}
                              onChange={addNewNameOptionOnChange}
                              value={optionName}
                            />

                            <TextField
                              required={true}
                              label={'Цена'}
                              onKeyDown={(e) => e.stopPropagation()}
                              onChange={addNewPriceOptionOnChange}
                              value={optionPrice}
                            />

                            <Button
                              sx={{ my: 2 }}
                              onClick={addNewOption}
                              variant={'contained'}
                            >
                              Добавить
                            </Button>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>

                      {optionList.length === 0 ? (
                        <></>
                      ) : (
                        optionList.map((item) => (
                          <Card
                            key={item.id}
                            sx={{ m: 1 }}
                          >
                            <ListItem
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box sx={{ display: 'block' }}>
                                <Typography variant={'subtitle2'}>
                                  {item.name}
                                </Typography>

                                <Typography variant={'subtitle2'}>
                                  {item.price} P
                                </Typography>
                              </Box>
                              <div>
                                <IconButton
                                  onClick={() =>
                                    editIsOpenHandler(item.id, !editIsOpen.open)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  color={'abortButton'}
                                  onClick={() => deleteOptionItem(item.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </ListItem>

                            {editIsOpen.id === item.id && editIsOpen.open ? (
                              <Box sx={{ m: 1 }}>
                                <TextField
                                  onChange={addNewNameOptionOnChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  label={'Название'}
                                />
                                <TextField
                                  onChange={addNewPriceOptionOnChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  sx={{ my: 1 }}
                                  label={'Цена'}
                                />
                                {/*<button onClick={() => console.log(item.id)}>show</button>*/}
                                <Button
                                  variant={'contained'}
                                  onClick={() => updateOptionItem(item.id)}
                                >
                                  Сохранить
                                </Button>
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Card>
                        ))
                      )}
                    </List>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'center', my: 2 }}
                    >
                      <Pagination
                        page={pageCities}
                        siblingCount={!matches ? 0 : 1}
                        size={!matches ? 'small' : 'medium'}
                        onChange={(e, value) => setPageCities(value)}
                        count={citiesLastPage}
                        variant='outlined'
                        shape='rounded'
                      />
                    </Box>
                  </Box>
                </Drawer>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Категории расходов</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={() => setIsOpenTypesCategory(true)}
                >
                  Категории
                </Button>
                <Drawer
                  sx={{ zIndex: 99999 }}
                  anchor={'left'}
                  open={isOpenTypesCategory}
                  onClose={() => setIsOpenTypesCategory(false)}
                >
                  <Box sx={{ width: 350 }}>
                    <TreeView
                      defaultExpanded={['1']}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ flexGrow: 1, maxWidth: 350, overflowY: 'auto' }}
                    >
                      {renderTree(flowTypeList)}
                    </TreeView>
                  </Box>
                </Drawer>
              </Box>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={closeDialog}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        title={'Удалить категорию?'}
        buttonTitle={'Удалить'}
        event={categoryConfirmHandleClick}
        name={'deleteCategory'}
      />

      <Dialog
        sx={{ zIndex: '999999999' }}
        fullWidth
        open={showConfirm}
        maxWidth='sm'
        scroll='body'
        onClose={closeConfirmDialog}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Удалить регион/город?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={optionConfirmHandleClick}
          >
            Удалить
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={closeConfirmDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ zIndex: 9999999 }}
        fullWidth
        open={categoryDialogIsOpen}
        onClose={closeCategoryDialogHandler}
        maxWidth='sm'
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 2, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Typography variant='h5'>{categoryEvent}</Typography>
          </Box>
          {categoryEvent === 'Удалить категорию' ? (
            <></>
          ) : (
            <Box>
              {/*<button onClick={showMeMore}>show</button>*/}
              <TextField
                autoFocus
                sx={{ mt: 2 }}
                fullWidth
                onKeyDown={(e) => e.stopPropagation()}
                onChange={categoryNameOnChange}
                label={'Название категории'}
                defaultValue={categoryNameNode}
                name={'categoryName'}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            onClick={createNewCategoryType}
            variant='contained'
            sx={{ marginRight: 1 }}
          >
            {buttonTitleDialog}
          </Button>
          <Button
            onClick={closeCategoryDialogHandler}
            variant='contained'
            color='secondary'
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ zIndex: 99999 }}
        fullWidth
        open={openConfirmDeleteDialog}
        maxWidth='md'
        scroll='body'
        onClose={() => setOpenConfirmDeleteDialog(false)}
      >
        <DialogContent
          sx={{
            pb: 8,
            px: { xs: 2, sm: 4 },
            pt: { xs: 8, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography
              variant='h5'
              sx={{ mb: 3 }}
            >
              Подтвердите удаление
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            color='abortButton'
            sx={{ marginRight: 1 }}
            onClick={confirmProductTypeDelete}
          >
            Удалить
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpenConfirmDeleteDialog(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogAppSetting;
