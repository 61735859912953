import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { apiGet, apiPost } from '../../../api';
import React, { useEffect, useState } from 'react';
import errorHandler from '../errorHandler';
import AddNewInvoice from '../../common/dialogs/newInvoice';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Link,
  Pagination,
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DeleteForever, Solid } from 'mdi-material-ui';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Tooltip from '@mui/material/Tooltip';
import { Controller, useForm } from 'react-hook-form';
import { Edit } from '@mui/icons-material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useSelector } from 'react-redux';
import { theme } from '../../common/componentColors';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ru } from 'date-fns/locale';
import { editBalance } from '../../../helpers/BalanceHelper';
import { FIRST_MONTH_DAY, THIS_DAY } from '../../../constants/days';
import { HeaderPage } from '../../common/headerPage/HeaderPage';
import { PermissionWrapper } from '../../common/permissionWrapper/PermissionWrapper';
import { CustomCell } from '../../common/tables/CustomCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#e3e3e3',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableInvoiceRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#9cd7a2',
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 2,
  },
}));

const StyledBoxTop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 462)]: {
    flexDirection: 'column-reverse',
  },
}));
const StyledBoxWrapButtons = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 592)]: {
    flexDirection: 'column',
    marginTop: 14,
  },
}));
const StyledButtonEddWrap = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 462)]: {
    marginTop: 14,
  },
}));
const StyledButtonEdd = styled(Button)(({ theme }) => ({
  [theme.breakpoints.between(427, 462)]: {
    width: 270,
  },
  [theme.breakpoints.between(0, 427)]: {
    width: 200,
  },
}));
const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.between(540, 984)]: {
    width: 150,
    height: 80,
    margin: 6,
    marginTop: 12,
  },
  // [theme.breakpoints.between(427,540)]: {
  //     width:120,
  //     height:70,
  //     margin:4,
  //     marginTop:12
  // },
  [theme.breakpoints.between(420, 592)]: {
    width: 300,
    height: 80,
    margin: 4,
    marginTop: 12,
  },
  [theme.breakpoints.between(0, 420)]: {
    width: 220,
    height: 75,
    margin: 4,
    marginTop: 8,
  },
}));
const StyledIconButtonLeft = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.between(540, 984)]: {
    bottom: -16,
    right: 17,
  },
  // [theme.breakpoints.between(427,540)]: {
  //     bottom:-10,
  //     right:16
  // },
  [theme.breakpoints.between(420, 592)]: {
    bottom: -10,
    right: -124,
  },
  [theme.breakpoints.between(0, 420)]: {
    bottom: -10,
    right: -48,
  },
}));
const StyledIconButtonRight = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.between(595, 984)]: {
    bottom: -16,
    right: 17,
  },
  // [theme.breakpoints.between(591, 595)]: {
  //     right: -80,
  //     bottom: -10
  // },
  [theme.breakpoints.between(591, 595)]: {
    bottom: -16,
    right: 17,
  },
  [theme.breakpoints.between(420, 592)]: {
    bottom: -11,
    right: -124,
  },
  [theme.breakpoints.between(0, 420)]: {
    bottom: -11,
    right: -48,
  },
}));
const StyledAddBoxIcon = styled(AddBoxIcon)(({ theme }) => ({
  [theme.breakpoints.between(0, 984)]: {
    fontSize: 32,
  },
  // [theme.breakpoints.between(0,427)]: {
  //     fontSize:40
  // },
}));
const StyledIndeterminateCheckBoxIcon = styled(IndeterminateCheckBoxIcon)(
  ({ theme }) => ({
    [theme.breakpoints.between(0, 984)]: {
      fontSize: 32,
    },
    // [theme.breakpoints.between(0,427)]: {
    //     fontSize:40
    // },
  }),
);
const StyledIconTable = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.between(600, 663)]: {
    fontSize: 20,
    padding: 3,
  },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(540, 999)]: {
    fontSize: 17,
  },
  [theme.breakpoints.between(0, 540)]: {
    fontSize: 16,
  },
}));

const TableWrapBox = styled(Box)(({ theme }) => ({}));

const StyledBoxIncomeTop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 995)]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  [theme.breakpoints.between(0, 425)]: {
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
const StyledBoxWrapExpenses = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 1245)]: {
    flexDirection: 'column',
  },
}));
const StyledBoxTree = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 1245)]: {
    width: '100%',
    marginTop: 0,
  },
  [theme.breakpoints.between(0, 500)]: {
    maxWidth: 220,
  },
}));
const StyledBoxIncomeTopDatePicker = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(0, 592)]: {},
  [theme.breakpoints.between(0, 427)]: {
    flexDirection: 'column',
    margin: 0,
    marginBottom: 14,
    alignItems: 'center',
  },
}));
const StyledDatePickersIncome = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.between(0, 427)]: {
    margin: 0,
    marginTop: 12,
    width: 220,
  },
}));

const InvoiceKeeper = () => {
  const { reset, control, handleSubmit, setValue, watch } = useForm();

  const [invoiceList, setInvoiceList] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const open = useSelector((state) => state.layout.open);
  const [userAccess, setUserAccess] = useState({});

  // Добавить значение счета
  const [incrementDialogOpen, setIncrementDialogOpen] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [amountInvoice, setAmountInvoice] = useState('');
  const [descriptionInvoice, setDescriptionInvoice] = useState('');
  const [currentAccount, setCurrentAccount] = useState([]);

  // Добавить категорию счета
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);

  // Список всех типов расходов
  const [flowTypeList, setFlowTypeList] = useState([]);

  // Состояния модального окна
  const [categoryIdForInvoice, setCategoryIdForInvoice] = useState('');
  const [categoryNameForInvoice, setCategoryNameForInvoice] =
    useState('Категории');
  const [categoryDescriptionForInvoice, setCategoryDescriptionForInvoice] =
    useState('');
  const [
    categoryInitialBalanceForInvoice,
    setCategoryInitialBalanceForInvoice,
  ] = useState('');
  const [isCategoryShow, setIsCategoryShow] = useState(false);
  const [itemInvoice, setItemInvoice] = useState('');
  const [tableNumber, setTableNumber] = useState('Баланс');
  const [expensesList, setExpensesList] = useState([]);
  const [comingPaymentList, setComingPaymentList] = useState([]);
  const [totalBalance, setTotalBalance] = useState({});
  const [userRole, setUserRole] = useState('');
  const [companyAccountsId, setCompanyAccountsId] = useState('');

  const [dateValue, setDateValue] = useState(new Date());

  // Пагинация баланса
  const [page, setPage] = useState('1');
  const [lastPage, setLastPage] = useState('');

  // Пагинация дохода
  const [lastPageComingPayment, setLastPageComingPayment] = useState('');
  const [pageComingPayment, setPageComingPayment] = useState('');

  // Пагинация расхода
  const [lastPageExpensesPayment, setLastPageExpensesPayment] = useState('');
  const [pageExpensesPayment, setPageExpensesPayment] = useState('1');

  // Дата - начало и конец платежей доходов
  const [startDate, setStartDate] = useState('');
  const [stopDate, setStopDate] = useState('');

  // Дата - начало и конец платежей доходов
  // const [expensesStartDate, setExpensesStartDate] = useState("");
  // const [expensesStopDate, setExpensesStopDate] = useState("");

  // checkBox Render Tree
  const [renderTreeCheckBoxHandler, setRenderTreeCheckBoxHandler] = useState(
    [],
  );

  // Удаление транзакции и подтверждающее окно для удаления
  const [idDeleteTransaction, setIdDeleteTransaction] = useState(false);
  const [
    openConfirmDeleteTransactionDialog,
    setOpenConfirmDeleteTransactionDialog,
  ] = useState(false);

  // Общая сумма доходов
  const [totalAmount, setTotalAmount] = useState('');

  // Открыть модалку для подтверждения удаления счета (Баланс)
  const [showDeleteInvoiceHandleDialog, setShowDeleteInvoiceHandleDialog] =
    useState(false);
  const [invoiceId, setInvoiceId] = useState('');

  // Открыть модалку для редактирования счета (Баланс)
  const [showEditInvoiceHandleDialog, setShowEditInvoiceHandleDialog] =
    useState(false);

  // Открыть модалку для перевода средств на другой счет (Баланс)
  const [showTransferDialog, setShowTransferDialog] = useState(false);

  // Установить Id при переводе средств (Баланс)
  const [idInvoiceFrom, setIdInvoiceFrom] = useState('');
  const [idInvoiceTo, setIdInvoiceTo] = useState('');

  // Описание и сумма перевода (Баланс)
  const [sumOfTransfer, setSumOfTransfer] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [descriptionOfTransfer, setDescriptionOfTransfer] = useState('');

  // Имя счета с которого делается перевод (Баланс)
  const [nameOfTransferInvoice, setNameOfTransferInvoice] = useState('');

  // Новый список счетов на который отправить - без того счета откуда отправляется
  const [chooseInvoiceListToTransfer, setChooseInvoiceListToTransfer] =
    useState([]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [todaySearchDate, setTodaySearchDate] = useState('');
  const [nextSearchDate, setNextSearchDate] = useState('');

  // Варианты аренды
  const [rentList, setRentList] = useState([]);

  const isCategoryChange = useSelector(
    (state) => state.app.isCategoryPropChange,
  );

  const getFirstDay = (date) => {
    let arr = [];
    let newDate = date.toLocaleDateString('ru-RU').split('.');
    newDate[0] = '01';
    newDate.map((item) => {
      arr.unshift(item);
    });
    setTodaySearchDate(arr.join('-'));
  };

  const getLastDay = (date) => {
    let arr = [];
    const newDate = date.toLocaleDateString('ru-RU').split('.');
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    newDate[0] = lastDay.getDate();
    newDate.map((item) => {
      arr.unshift(item);
    });
    setNextSearchDate(arr.join('-'));
  };

  //Поиск по счетам в расходах

  const [sortTableCompanyAccount, setSortTableCopanyAccount] = useState('');
  const [companyAccountSortList, setCompanyAccountSortList] = useState([]);

  const changePage = (event, value) => {
    setPage(value);
  };

  const changePageComingPayment = (event, value) => {
    setPageComingPayment(value.toString());
  };

  const changePageExpensesPayment = (event, value) => {
    setPageExpensesPayment(value);
  };

  const closeDialog = () => {
    setIncrementDialogOpen(false);
    setAmountInvoice('');
    setCategoryNameForInvoice('Категории');
    setCategoryIdForInvoice('');
    setTransactionId('');
    setItemInvoice('');
    setDescriptionInvoice('');
    setIsButtonDisabled(false);
  };

  const getFlowTypeList = () => {
    apiGet({
      url: '/flow-type/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setFlowTypeList(res.data);
    });
  };

  // Получаем варианты сортировки по арендам
  useEffect(() => {
    const newRentListItem = [{ key: '', value: 'Все' }];
    setRentList(newRentListItem);
    apiGet({
      url: '/payment-transaction/rental-status-list',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setRentList((prevState) => [...prevState.concat(res.data)]);
    });
  }, []);

  useEffect(() => {
    getFlowTypeList();
  }, [isCategoryChange]);

  const getInvoiceList = () => {
    apiGet({
      url: '/company-accounts/index',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
    });
  };

  const getAccTransComingList = () => {
    apiPost({
      url: `/account-transaction/index?accounting=1&page=${pageComingPayment}&limit=10`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setComingPaymentList(res.data.models);
      setLastPageComingPayment(res.data.lastPage);
    });
  };

  const getAccTransExpensList = () => {
    apiPost({
      url: `/account-transaction/index?page=${pageExpensesPayment}&accounting=2&limit=10`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setExpensesList(res.data.models);
      setLastPageExpensesPayment(res.data.lastPage);
    });
  };

  useEffect(() => {
    apiGet({
      url: `/company-accounts/index?page=${page}&limit=10`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setInvoiceList(res.data.models);
      setLastPage(res.data.lastPage);
    });
  }, [page]);

  useEffect(() => {
    if (!watch('rentalStatus')) {
      setValue('rentalStatus', '');
    }
    apiPost({
      url: `/account-transaction/index?accounting=1&page=${pageComingPayment}&rentalStatus=${watch(
        'rentalStatus',
      )}&company_accounts_id=${companyAccountsId}&fromDateCreate=${startDate}&toDateCreate=${stopDate}&limit=10`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTotalAmount(res.data.sum);
      setComingPaymentList(res.data.models);
      setLastPageComingPayment(res.data.lastPage);
    });
  }, [
    pageComingPayment,
    watch('rentalStatus'),
    companyAccountsId,
    startDate,
    stopDate,
  ]);

  useEffect(() => {
    const data = {};
    data.flow_types = renderTreeCheckBoxHandler;

    apiPost({
      url: `/account-transaction/index?page=${pageExpensesPayment} &accounting=2&fromDateCreate=${
        todaySearchDate || FIRST_MONTH_DAY
      }&toDateCreate=${
        nextSearchDate || THIS_DAY
      }&company_accounts_id=${sortTableCompanyAccount}&limit=10`,
      postData: data,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setExpensesList(res.data.models);
      setLastPageExpensesPayment(res.data.lastPage);
    });
  }, [
    pageExpensesPayment,
    sortTableCompanyAccount,
    todaySearchDate,
    nextSearchDate,
    // expensesStartDate,
    // expensesStopDate,
    renderTreeCheckBoxHandler,
  ]);

  const companyBalance = () => {
    apiGet({
      url: '/company-accounts/balance',
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setTotalBalance(res.data);
    });
  };

  useEffect(() => {
    const userAccessLocal = JSON.parse(
      window.localStorage.getItem('userAccess'),
    );
    setUserAccess(userAccessLocal);

    const userRoleLocal = window.localStorage.getItem('userRole');
    setUserRole(userRoleLocal);
    companyBalance();
    // getFlowTypeList();
    getInvoiceList();
    getCompanyList();
  }, []);

  const openDialogHandler = () => {
    setIsDialogOpen(true);
  };

  const transactionIncrementOnChange = (id, name, isCatShow, e) => {
    setIncrementDialogOpen(true);
    setIsCategoryShow(isCatShow);
    e.stopPropagation();
  };

  const transactionIncrement = () => {
    setIsButtonDisabled(true);
    const data = {};
    let url = '';
    if (amountInvoice !== '') data.amount = amountInvoice;

    if (descriptionInvoice !== '') data.description = descriptionInvoice;

    if (!isCategoryShow)
      url = `/account-transaction/add?company_accounts_id=${transactionId}`;
    else
      url = `/account-transaction/down?company_accounts_id=${transactionId}&flow_type_id=${categoryIdForInvoice}`;

    apiPost({
      url: url,
      postData: data,
    }).then((res) => {
      if (res.error) {
        setIsButtonDisabled(false);
        return errorHandler(res.data);
      }
      closeDialog();
      companyBalance();
      getInvoiceList();
      if (categoryIdForInvoice === '') getAccTransComingList();
      else getAccTransExpensList();
      if (
        url === `/account-transaction/add?company_accounts_id=${transactionId}`
      ) {
        toast.success('Добавлен успешно !');
      } else {
        toast.success('Списано успешно !');
      }
      setIsButtonDisabled(false);
    });
  };

  const showCategory = () => {
    setOpenCategoryDialog(true);
  };

  const categoryIdAndNameForInvoice = (id, name) => {
    setCategoryNameForInvoice(name);
    setCategoryIdForInvoice(id);
    setOpenCategoryDialog(false);
  };

  const itemInvoiceOnChange = (e) => {
    setItemInvoice(e.target.value);
    setTransactionId(e.target.value);
  };

  const getCorrectDate = (date, event) => {
    let arr = [];
    let dateArr = [];
    arr = date.split('-');
    dateArr.push(arr[2]);
    dateArr.push(arr[1]);
    dateArr.push(arr[0]);
    dateArr.join('');
    event(dateArr.join('.'));
  };

  const acceptStartPaymentDateOnChange = (e) => {
    getCorrectDate(e.target.value, setStartDate);
  };

  const acceptStopPaymentDateOnChange = (e) => {
    getCorrectDate(e.target.value, setStopDate);
    setPageComingPayment('1');
  };

  const acceptStartExpensesPaymentDateOnChange = (e) => {
    setTodaySearchDate(e.target.value);
    // getCorrectDate(e.target.value, setTodaySearchDate);
  };

  const acceptStopExpensesPaymentDateOnChange = (e) => {
    // getCorrectDate(e.target.value, setExpensesStopDate);
    setNextSearchDate(e.target.value);
    setPageExpensesPayment('1');
  };
  const chooseCompanyAccount = (e) => {
    setSortTableCopanyAccount(e.target.value);
  };

  const flowTypeIdOnChange = (e) => {
    if (e.target.value === 99) return setCompanyAccountsId('');
    setCompanyAccountsId(e.target.value);
    setPageComingPayment('1');
  };

  const getAllcategoryPayments = () => {
    setPageExpensesPayment('1');
    setRenderTreeCheckBoxHandler('');
    // setFlowTypeId('')
  };

  let updatedList = [...renderTreeCheckBoxHandler];

  const handleChangeRenderTreeCheckBox = (event, id, node) => {
    if (event.target.checked) {
      if (!renderTreeCheckBoxHandler.includes(id)) {
        updatedList = [...updatedList, id];
      }
    } else {
      if (renderTreeCheckBoxHandler.includes(id)) {
        updatedList.splice(updatedList.indexOf(id), 1);
      }
    }

    if (node.children === null || node.children.length === 0) {
      return setRenderTreeCheckBoxHandler(updatedList);
    } else {
      node.children.map((item) => {
        handleChangeRenderTreeCheckBox(event, item.id, item);
      });
    }
    setRenderTreeCheckBoxHandler(updatedList);
  };

  const deleteTransaction = (id) => {
    setIdDeleteTransaction(id);
    setOpenConfirmDeleteTransactionDialog(true);
  };

  const confirmDeleteTransaction = () => {
    apiGet({
      url: `/account-transaction/delete?id=${idDeleteTransaction}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Удалено');
      getAccTransComingList();
      getAccTransExpensList();
      companyBalance();
    });
    setOpenConfirmDeleteTransactionDialog(false);
  };

  const confirmDeleteInvoice = () => {
    apiGet({
      url: `/company-accounts/delete?id=${invoiceId}`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Удалено');
      getInvoiceList();
    });
    setShowDeleteInvoiceHandleDialog(false);
  };

  const deleteInvoiceHandle = (id) => {
    setInvoiceId(id);
    setShowDeleteInvoiceHandleDialog(true);
  };

  const editInvoiceHandle = (id, name, description, sum) => {
    setCategoryNameForInvoice(name);
    setCategoryDescriptionForInvoice(description);
    setCategoryInitialBalanceForInvoice(sum);
    setInvoiceId(id);
    setShowEditInvoiceHandleDialog(true);
  };

  const changeDateHandler = (value) => {
    setDateValue(value);
    getFirstDay(value);
    getLastDay(value);
  };

  const upDateHandler = () => {
    setDateValue(new Date(dateValue.setMonth(dateValue.getMonth() + 1)));
    getFirstDay(dateValue);
    getLastDay(dateValue);
  };

  const downDateHandler = () => {
    setDateValue(new Date(dateValue.setMonth(dateValue.getMonth() - 1)));
    getFirstDay(dateValue);
    getLastDay(dateValue);
  };

  const confirmEditInvoice = (formData) => {
    for (let key in formData) {
      if (formData[key] === undefined) delete formData[key];
    }
    if (categoryNameForInvoice !== '') formData.name = categoryNameForInvoice;

    formData.description = categoryDescriptionForInvoice;

    formData.initial_balance = categoryInitialBalanceForInvoice;

    apiPost({
      url: `/company-accounts/update?id=${invoiceId}`,
      postData: formData,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      toast.success('Данные счета обновлены');
      getInvoiceList();
      closeEditDialog();
    });
  };

  const getCompanyList = () => {
    apiGet({
      url: `/company-accounts/index`,
    }).then((res) => {
      if (res.error) return errorHandler(res.data);
      setCompanyAccountSortList(res.data.models);
    });
  };

  const closeEditDialog = () => {
    setShowEditInvoiceHandleDialog(false);
    reset();
    setCategoryNameForInvoice('');
  };

  const closeTransferDialog = () => {
    setShowTransferDialog(false);
    setSumOfTransfer('');
    setDescriptionOfTransfer('');
    setIdInvoiceTo('');
    setIdInvoiceFrom('');
  };

  const openTransferDialog = (id, name, sum) => {
    setTotalSum(sum);
    setNameOfTransferInvoice(name);
    setShowTransferDialog(true);
    setIdInvoiceFrom(id);
    const newList = invoiceList.filter((item) => item.id !== id);
    setChooseInvoiceListToTransfer(newList);
  };

  const transferMoneyToAnotherInvoice = () => {
    setIsButtonDisabled(true);
    const data = {};
    data.sum = sumOfTransfer;
    data.description = descriptionOfTransfer;

    // Перевод средств
    apiPost({
      url: `/company-accounts/money-transfer?from=${idInvoiceFrom}&to=${idInvoiceTo}`,
      postData: data,
    }).then((res) => {
      if (res.error) {
        setIsButtonDisabled(false);
        return errorHandler(res.data);
      }
      toast.success('Перевод выполнен');
      getInvoiceList();
      setShowTransferDialog(false);
      setIsButtonDisabled(false);
      setSumOfTransfer('');
    });
  };

  const chooseInvoiceToItem = (e) => {
    setIdInvoiceTo(e.target.value);
  };

  const renderTree = (nodes) => (
    <TreeItem
      sx={{
        '& .MuiTreeItem-content': { p: 1 },
      }}
      key={nodes.id}
      nodeId={nodes.id + ''}
      label={
        nodes.name === 'root' ? (
          <Typography variant={'h6'}>Категории</Typography>
        ) : (
          nodes.name
        )
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => (
            <Box
              key={node.id}
              sx={{ display: 'flex' }}
            >
              {renderTree(node)}
              {node.children.length === 0 ? (
                <Box title={'Выбрать категорию'}>
                  <IconButton
                    // sx={{ borderRadius: 2, height: 1, p: 0 }}
                    color={'primary'}
                    onClick={() =>
                      categoryIdAndNameForInvoice(node.id, node.name)
                    }
                  >
                    <AddBoxIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          ))
        : null}
    </TreeItem>
  );

  const transactionRenderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id + ''}
      label={
        nodes.name === 'root' ? (
          <Button
            sx={{ height: 30 }}
            variant={'contained'}
            onClick={getAllcategoryPayments}
          >
            Все расходы
          </Button>
        ) : (
          nodes.name
        )
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => (
            <Box
              key={node.id}
              sx={{ display: 'flex', my: 0.5 }}
            >
              <FormControlLabel
                name='checkbox'
                sx={{
                  height: 20,
                  p: 0,
                  m: 0,
                  '& :hover': { m: 0, borderRadius: 1 },
                }}
                control={
                  <Checkbox
                    checked={renderTreeCheckBoxHandler.includes(node.id)}
                  />
                }
                onChange={(event) =>
                  handleChangeRenderTreeCheckBox(event, node.id, node)
                }
              />
              {transactionRenderTree(node)}
            </Box>
          ))
        : null}
    </TreeItem>
  );

  return (
    <div>
      <Box>
        <HeaderPage title='Бухгалтерия'>
          <PermissionWrapper permits={['company-accounts', ['create']]}>
            <StyledButtonEddWrap>
              <StyledButtonEdd
                variant='contained'
                onClick={openDialogHandler}
              >
                Добавить счет
              </StyledButtonEdd>
            </StyledButtonEddWrap>
          </PermissionWrapper>
        </HeaderPage>

        <StyledBoxWrapButtons
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link
            underline='none'
            href={'#'}
            onClick={() => setTableNumber('Баланс')}
          >
            <StyledCard
              raised
              sx={{ backgroundColor: '#ffd52e', height: 100, width: 200, m: 2 }}
            >
              <Box
                sx={{
                  width: 154,
                  justifyContent: 'center',
                  marginTop: 1,
                  mx: 2,
                }}
              >
                <StyledTypography variant={'h6'}>
                  {totalBalance?.overall_balance
                    ? editBalance(totalBalance?.overall_balance)
                    : '0.00 ₽'}
                </StyledTypography>
                <div>
                  <StyledTypography
                    className='whitespace-nowrap'
                    variant={'subtitle1'}
                  >
                    Баланс
                  </StyledTypography>
                </div>
              </Box>
            </StyledCard>
          </Link>

          <Link
            underline='none'
            href={'#'}
            onClick={() => setTableNumber('Доходы')}
          >
            <StyledCard
              raised
              sx={{ backgroundColor: '#48be2e', height: 100, width: 200, m: 2 }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: 154,
                  justifyContent: 'center',
                  marginTop: 1,
                  mx: 2,
                }}
              >
                <StyledTypography variant={'h6'}>
                  {totalBalance?.income_balance
                    ? editBalance(totalBalance?.income_balance)
                    : '0.00 ₽'}
                </StyledTypography>
                <div>
                  <StyledTypography
                    className='whitespace-nowrap'
                    variant={'subtitle1'}
                  >
                    Доходы
                  </StyledTypography>
                </div>
                {userAccess['account-transaction']?.add ? (
                  <Tooltip title={'Внести сумму на счет'}>
                    <StyledIconButtonLeft
                      onClick={(e) =>
                        transactionIncrementOnChange('', '', false, e)
                      }
                      sx={{
                        color: '#53965e',
                        position: 'absolute',
                        bottom: -35,
                        right: -30,
                        borderRadius: 1,
                        '&:hover': { background: 'none' },
                      }}
                    >
                      <StyledAddBoxIcon sx={{ fontSize: 40 }} />
                    </StyledIconButtonLeft>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Box>
            </StyledCard>
          </Link>
          {/*</Button>*/}

          <Link
            underline='none'
            href={'#'}
            onClick={() => setTableNumber('Расходы')}
          >
            <StyledCard
              raised
              sx={{ backgroundColor: '#f36400', height: 100, width: 200, m: 2 }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: 154,
                  justifyContent: 'center',
                  marginTop: 1,
                  mx: 2,
                }}
              >
                <StyledTypography variant={'h6'}>
                  {totalBalance?.flow_balance
                    ? editBalance(totalBalance?.flow_balance)
                    : '0.00 ₽'}
                </StyledTypography>
                <div>
                  <StyledTypography
                    className='whitespace-nowrap'
                    variant={'subtitle1'}
                  >
                    Расходы
                  </StyledTypography>
                </div>
                {userAccess['account-transaction']?.down ? (
                  <Tooltip title={'Указать сумму расхода'}>
                    <StyledIconButtonRight
                      onClick={(e) =>
                        transactionIncrementOnChange('', '', true, e)
                      }
                      sx={{
                        color: '#ab5b5b',
                        position: 'absolute',
                        bottom: -35,
                        right: -30,
                        borderRadius: 1,
                        '&:hover': { background: 'none' },
                      }}
                    >
                      <StyledIndeterminateCheckBoxIcon sx={{ fontSize: 40 }} />
                    </StyledIconButtonRight>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Box>
            </StyledCard>
          </Link>
        </StyledBoxWrapButtons>
      </Box>

      {tableNumber === 'Баланс' ? (
        <div>
          <Typography
            sx={{ my: 2 }}
            variant={'h4'}
            align={'center'}
          >
            Баланс
          </Typography>
          <TableWrapBox
            className={!open ? 'Overflow-box-show-menu' : 'Overflow-box'}
            sx={{ overflow: 'auto' }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 700 }}
                aria-label='customized table'
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='center'>Счет</StyledTableCell>
                    <StyledTableCell align='center'>
                      Сумма на счете
                    </StyledTableCell>
                    <StyledTableCell align='center'>Описание</StyledTableCell>
                    <StyledTableCell align='center'>Действие</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoiceList.map((item) => (
                    <StyledTableRow key={item.id}>
                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {item.name}
                      </CustomCell>
                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {editBalance(item?.sum)}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {item.description}
                      </CustomCell>

                      <CustomCell
                        sx={{ whiteSpace: 'nowrap', py: 0.5 }}
                        align='center'
                        component='th'
                        scope='row'
                      >
                        <Tooltip title={'Перевод между счетами'}>
                          <StyledIconTable
                            sx={{
                              backgroundColor: '#fff',
                              mr: 1,
                              '&:hover': { backgroundColor: '#c2c2c2' },
                            }}
                            onClick={() =>
                              openTransferDialog(item.id, item.name, item?.sum)
                            }
                          >
                            <SyncAltIcon color={'primary'} />
                          </StyledIconTable>
                        </Tooltip>

                        {/*{userRole === "admin" ? <>*/}
                        {userAccess['company-accounts'].update ? (
                          <Tooltip title={'Редактировать'}>
                            <StyledIconTable
                              sx={{
                                backgroundColor: '#fff',
                                mr: 1,
                                '&:hover': { backgroundColor: '#c2c2c2' },
                              }}
                              onClick={() => {
                                editInvoiceHandle(
                                  item.id,
                                  item.name,
                                  item.description,
                                  item.sum,
                                );
                              }}
                            >
                              <Edit color={'primary'} />
                            </StyledIconTable>
                          </Tooltip>
                        ) : (
                          <></>
                        )}

                        {userAccess['company-accounts'].delete ? (
                          <Tooltip title={'Удалить счет'}>
                            <StyledIconTable
                              sx={{
                                backgroundColor: '#fff',
                                mr: 1,
                                '&:hover': { backgroundColor: '#c2c2c2' },
                              }}
                              onClick={() => deleteInvoiceHandle(item.id)}
                            >
                              <DeleteForever color={'abortButton'} />
                            </StyledIconTable>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </CustomCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapBox>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              onChange={changePage}
              count={lastPage || 0}
              variant='outlined'
              shape='rounded'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}

      {tableNumber === 'Доходы' ? (
        <div>
          <StyledBoxIncomeTop
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <Typography
                sx={{ my: 2, ml: 2 }}
                variant={'h4'}
                align={'center'}
              >
                Доходы
              </Typography>
            </div>
            <StyledBoxIncomeTopDatePicker sx={{ display: 'flex', my: 2 }}>
              <StyledDatePickersIncome
                sx={{ mr: 2, width: 160 }}
                onChange={acceptStartPaymentDateOnChange}
                name='date'
                type='date'
                label={'С какого'}
                defaultValue={new Date()}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <StyledDatePickersIncome
                onChange={acceptStopPaymentDateOnChange}
                name='date'
                sx={{ width: 160 }}
                type='date'
                label={'По какое'}
                defaultValue={new Date()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </StyledBoxIncomeTopDatePicker>
          </StyledBoxIncomeTop>

          <TableWrapBox
            className={
              !open
                ? 'Overflow-box-inKepeper-income-show-menu'
                : 'Overflow-box-inKepeper-income'
            }
            sx={{ overflow: 'auto' }}
          >
            <TableContainer>
              <Table
                sx={{ minWidth: 1120 }}
                aria-label='customized table'
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align='center'>
                      <TextField
                        sx={{ width: 160 }}
                        select
                        fullWidth
                        defaultValue={itemInvoice}
                        onChange={flowTypeIdOnChange}
                        label={'Список счетов'}
                      >
                        <MenuItem value={99}>Все</MenuItem>
                        {invoiceList.map((invoiceItem) => (
                          <MenuItem
                            key={invoiceItem.id}
                            value={invoiceItem.id}
                          >
                            {invoiceItem.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ whiteSpace: 'nowrap' }}
                      align='center'
                    >
                      Сумма на счете
                    </StyledTableCell>

                    <StyledTableCell align='center'>Дата</StyledTableCell>

                    <StyledTableCell>
                      <Controller
                        align='center'
                        name='rentalStatus'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            onChange={onChange}
                            select
                            sx={{ maxWidth: 200 }}
                            fullWidth
                            label={'Тип аренды'}
                          >
                            {rentList.map((i) => (
                              <MenuItem
                                key={i.key}
                                value={i.key}
                              >
                                {i.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </StyledTableCell>

                    <StyledTableCell
                      align='center'
                      sx={{ width: '40%' }}
                    >
                      Описание
                    </StyledTableCell>

                    {userAccess['account-transaction'].delete && (
                      <StyledTableCell align='center'>Действия</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableInvoiceRow sx={{}}>
                    <StyledTableCell align={'center'}>
                      <Typography
                        colSpan={2}
                        variant={'subtitle2'}
                      >
                        Общая сумма:
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell
                      colSpan={4}
                      align={'center'}
                      sx={{ width: 150 }}
                    >
                      <Typography variant={'subtitle2'}>
                        {totalAmount === null
                          ? '0 ₽'
                          : editBalance(totalAmount)}
                      </Typography>
                    </StyledTableCell>
                    {userAccess['account-transaction'].delete && (
                      <StyledTableCell></StyledTableCell>
                    )}
                  </StyledTableInvoiceRow>

                  {comingPaymentList.map((item) => (
                    <StyledTableRow key={item.id}>
                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {item?.companyAccounts?.name}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {editBalance(item?.amount)}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {item?.createdAt}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                        sx={{ width: 250 }}
                      >
                        {item?.rentalStatusText === null
                          ? '-'
                          : item?.rentalStatusText}
                      </CustomCell>

                      <CustomCell
                        align='center'
                        component='th'
                        scope='row'
                      >
                        {item?.description}
                      </CustomCell>

                      {userAccess['account-transaction'].delete && (
                        <CustomCell
                          align='center'
                          component='th'
                          scope='row'
                        >
                          <IconButton
                            onClick={() => deleteTransaction(item.id)}
                            color={'abortButton'}
                          >
                            <DeleteForever />
                          </IconButton>
                        </CustomCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TableWrapBox>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              onChange={changePageComingPayment}
              count={lastPageComingPayment || 0}
              variant='outlined'
              shape='rounded'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
      {tableNumber === 'Расходы' ? (
        <div
          sx={{
            display: 'flex',
          }}
        >
          <StyledBoxWrapExpenses
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <StyledBoxTree
              sx={{
                height: '',
                width: '35%',
                mt: 11,
                p: 1,
                mr: 2,
                border: '1px solid #536e7a',
                borderRadius: 1,
                [theme.breakpoints.between(0, 500)]: {
                  maxWidth: '100%',
                },
              }}
            >
              <TreeView
                aria-label='rich object'
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {transactionRenderTree(flowTypeList)}
              </TreeView>
            </StyledBoxTree>
            <Box
              sx={{
                minWidth: '75%',
              }}
            >
              <StyledBoxIncomeTop
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  my: 2,
                  flexWrap: 'wrap',
                  columnGap: 1,
                }}
              >
                <div>
                  <Typography
                    variant={'h4'}
                    align={'center'}
                  >
                    Расходы
                  </Typography>
                </div>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 2,
                    [theme.breakpoints.between(677, 995)]: {
                      marginTop: 2,
                    },
                    [theme.breakpoints.between(0, 677)]: {
                      alignItems: 'start',
                      flexDirection: 'column-reverse',
                    },
                  }}
                >
                  <StyledBoxIncomeTopDatePicker sx={{ display: 'flex' }}>
                    <StyledDatePickersIncome
                      onChange={acceptStartExpensesPaymentDateOnChange}
                      name='date'
                      type='date'
                      sx={{ mr: 2 }}
                      label={'С какого'}
                      value={todaySearchDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <StyledDatePickersIncome
                      onChange={acceptStopExpensesPaymentDateOnChange}
                      name='date'
                      type='date'
                      sx={{ width: 160 }}
                      label={'По какое'}
                      value={nextSearchDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </StyledBoxIncomeTopDatePicker>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      [theme.breakpoints.between(0, 677)]: {
                        my: 2,
                      },
                    }}
                  >
                    <IconButton onClick={downDateHandler}>
                      <ArrowBackIosNewIcon />
                    </IconButton>
                    <Box sx={{ width: 160 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                      >
                        <DatePicker
                          views={['year', 'month']}
                          label='Год и месяц'
                          value={dateValue}
                          onChange={changeDateHandler}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <IconButton onClick={upDateHandler}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledBoxIncomeTop>
              <TableWrapBox
                className={
                  !open
                    ? 'Overflow-box-inKepeper-show-menu'
                    : 'Overflow-box-inKepeper'
                }
                sx={{ overflow: 'auto' }}
              >
                <TableContainer>
                  <Table
                    sx={{ minWidth: 700 }}
                    aria-label='customized table'
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ width: 100, py: 0.6 }}
                          align='center'
                        >
                          Сумма
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ py: 0.6 }}
                          align='center'
                        >
                          <TextField
                            sx={{ width: 130 }}
                            label={'Счет'}
                            defaultValue={''}
                            onChange={chooseCompanyAccount}
                            select
                          >
                            <MenuItem value={''}>Все</MenuItem>
                            {companyAccountSortList.map((i) => (
                              <MenuItem
                                key={i.id}
                                value={i.id}
                              >
                                {i.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ py: 0.6 }}
                          align='center'
                        >
                          Категория
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ py: 0.6 }}
                          align='center'
                        >
                          Комментарий
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ py: 0.6 }}
                          align='center'
                        >
                          Дата
                        </StyledTableCell>
                        {/*<StyledTableCell align="center">Описание</StyledTableCell>*/}

                        {userAccess['account-transaction'].delete && (
                          <StyledTableCell
                            sx={{ py: 0.6 }}
                            align='center'
                          >
                            Действия
                          </StyledTableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {expensesList.map((item) => (
                        <StyledTableRow key={item.id}>
                          <CustomCell
                            align='center'
                            component='th'
                            scope='row'
                          >
                            {editBalance(item?.amount)}
                          </CustomCell>

                          <CustomCell
                            align='center'
                            component='th'
                            scope='row'
                          >
                            {item?.companyAccounts?.name}
                          </CustomCell>

                          <CustomCell
                            align='center'
                            component='th'
                            scope='row'
                          >
                            {item?.flowType === null
                              ? '-'
                              : item?.flowType?.name}
                          </CustomCell>
                          <CustomCell
                            align='center'
                            component='th'
                            scope='row'
                          >
                            {item?.description === null
                              ? '-'
                              : item?.description}
                          </CustomCell>

                          <CustomCell
                            align='center'
                            component='th'
                            scope='row'
                          >
                            {item.createdAt}
                          </CustomCell>

                          {userAccess['account-transaction'].delete && (
                            <CustomCell
                              align='center'
                              component='th'
                              scope='row'
                            >
                              <IconButton
                                onClick={() => deleteTransaction(item.id)}
                                color={'abortButton'}
                              >
                                <DeleteForever />
                              </IconButton>
                            </CustomCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableWrapBox>
            </Box>
          </StyledBoxWrapExpenses>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              siblingCount={0}
              onChange={changePageExpensesPayment}
              count={lastPageExpensesPayment || 0}
              variant='outlined'
              shape='rounded'
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
      <AddNewInvoice
        setIsDialogOpen={setIsDialogOpen}
        isDialogOpen={isDialogOpen}
        event={getInvoiceList}
        setIsButtonDisabled={setIsButtonDisabled}
        isButtonDisabled={isButtonDisabled}
      />

      <Dialog
        fullWidth
        open={incrementDialogOpen}
        onClose={closeDialog}
        maxWidth='md'
        scroll='body'
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 2, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 2, textAlign: 'center' }}>
            <Typography variant='h5'>
              {!isCategoryShow ? 'Внести сумму на счет' : 'Сумма расхода'}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {isCategoryShow ? (
                <IconButton
                  color={
                    categoryNameForInvoice !== 'Категории'
                      ? 'secondary'
                      : 'grey'
                  }
                  sx={{ borderRadius: 2, display: 'block' }}
                  onClick={showCategory}
                >
                  <AddBoxIcon sx={{ fontSize: 70 }} />
                  <Typography>{categoryNameForInvoice}</Typography>
                </IconButton>
              ) : (
                <></>
              )}
            </Box>

            <TextField
              fullWidth
              sx={{ mt: 2 }}
              onChange={(e) => setAmountInvoice(e.target.value)}
              label={!isCategoryShow ? 'Сумма внесения' : 'Сумма расхода'}
              name={'invoicePayment'}
              type={'number'}
              required={true}
              min={'0'}
              error={
                !isCategoryShow
                  ? false
                  : amountInvoice > Math.floor(currentAccount.sum)
              }
              helperText={
                !isCategoryShow
                  ? ''
                  : amountInvoice > Math.floor(currentAccount.sum)
                  ? 'Недостаточно средств на счету'
                  : ''
              }
            />
            {isCategoryShow ? (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                onChange={(e) => setDescriptionInvoice(e.target.value)}
                label={'Комментарий'}
                name={'description'}
              />
            ) : null}
            <TextField
              required={true}
              sx={{ mt: 2 }}
              select
              fullWidth
              value={itemInvoice || ''}
              onChange={itemInvoiceOnChange}
              label={'Список счетов'}
            >
              {invoiceList.map((invoiceItem) => {
                return (
                  <MenuItem
                    key={invoiceItem.id}
                    onClick={() => setCurrentAccount(invoiceItem)}
                    value={invoiceItem.id}
                  >
                    {invoiceItem.name}
                  </MenuItem>
                );
              })}
            </TextField>

            {!isCategoryShow ? (
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                onChange={(e) => setDescriptionInvoice(e.target.value)}
                label={'Описание'}
                name={'invoiceDescription'}
              />
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            disabled={isButtonDisabled}
            onClick={transactionIncrement}
            variant='contained'
            sx={{ marginRight: 1 }}
          >
            Создать
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={closeDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>

      <Drawer
        sx={{ zIndex: 9991 }}
        anchor={'left'}
        open={openCategoryDialog}
        onClose={() => setOpenCategoryDialog(false)}
      >
        <Box sx={{ width: 350 }}>
          <TreeView
            defaultExpanded={['1']}
            aria-label='rich object'
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ flexGrow: 1, maxWidth: 350, overflowY: 'auto' }}
          >
            {renderTree(flowTypeList)}
          </TreeView>
        </Box>
      </Drawer>

      {/* Start - Подтверждение удаления транзакций*/}
      <Dialog
        fullWidth
        open={openConfirmDeleteTransactionDialog}
        onClose={() => setOpenConfirmDeleteTransactionDialog(false)}
        maxWidth='sm'
        scroll='body'
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 2, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant='h5'>Подтвердите удаление</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            color={'abortButton'}
            onClick={confirmDeleteTransaction}
            variant='contained'
            sx={{ marginRight: 1 }}
          >
            Удалить
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setOpenConfirmDeleteTransactionDialog(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/* End - Подтверждение удаления транзакций*/}

      {/* Start - Подтверждение удаления счета*/}
      <Dialog
        fullWidth
        open={showDeleteInvoiceHandleDialog}
        onClose={() => setShowDeleteInvoiceHandleDialog(false)}
        maxWidth='sm'
        scroll='body'
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 2, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant='h5'>Подтвердите удаление</Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            color={'abortButton'}
            onClick={confirmDeleteInvoice}
            variant='contained'
            sx={{ marginRight: 1 }}
          >
            Удалить
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setShowDeleteInvoiceHandleDialog(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/* End - Подтверждение удаления счета*/}

      {/* Start - Редактирования названия счета*/}
      <Dialog
        fullWidth
        open={showEditInvoiceHandleDialog}
        onClose={closeEditDialog}
        maxWidth='sm'
        scroll='body'
      >
        <form onSubmit={handleSubmit(confirmEditInvoice)}>
          <DialogContent
            sx={{
              pb: 2,
              px: { xs: 2, sm: 4 },
              pt: { xs: 2, sm: 12.5 },
              position: 'relative',
            }}
          >
            <Box sx={{ mb: 5, textAlign: 'center' }}>
              <Typography variant='h5'>Редактировать счет</Typography>
            </Box>

            <FormControl fullWidth>
              <Controller
                name='name'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    value={categoryNameForInvoice || ''}
                    required
                    name='name'
                    onBlur={onBlur}
                    onChange={(e) => setCategoryNameForInvoice(e.target.value)}
                    label={'Имя счета'}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                sx={{ my: 2 }}
                value={categoryDescriptionForInvoice || ''}
                name='description'
                onChange={(e) =>
                  setCategoryDescriptionForInvoice(e.target.value)
                }
                label={'Описание счета'}
              />
            </FormControl>
            {/* name='initial_balance' */}

            <TextField
              value={categoryInitialBalanceForInvoice || ''}
              // подтянуть
              label={'Начальный остаток'}
              type={'number'}
              onChange={(e) =>
                setCategoryInitialBalanceForInvoice(e.target.value)
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
          >
            <Button
              type={'submit'}
              variant='contained'
              sx={{ marginRight: 1 }}
            >
              Сохранить
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={closeEditDialog}
            >
              Отменить
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* End - Редактирование названия счета*/}

      {/* Start - Перевод средств с одного счета на другой*/}
      <Dialog
        fullWidth
        open={showTransferDialog}
        onClose={closeTransferDialog}
        maxWidth='sm'
        scroll='body'
      >
        <DialogContent
          sx={{
            pb: 2,
            px: { xs: 2, sm: 4 },
            pt: { xs: 2, sm: 12.5 },
            position: 'relative',
          }}
        >
          <Box sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant={'h5'}>
              Перевод средств со счета {nameOfTransferInvoice}
            </Typography>
          </Box>

          <FormControl fullWidth>
            <TextField
              onChange={chooseInvoiceToItem}
              // required={true}
              defaultValue={''}
              sx={{ my: 2 }}
              select
              fullWidth
              label={'Куда направить средства'}
            >
              {chooseInvoiceListToTransfer.map((invoiceItem) => (
                <MenuItem
                  key={invoiceItem.id}
                  onClick={() => setCurrentAccount(invoiceItem)}
                  value={invoiceItem.id}
                >
                  {invoiceItem.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              // Если надо убрать кнопку переводов всех средств удалить только value и саму кнопку соответственно
              value={sumOfTransfer !== '' ? sumOfTransfer : ''}
              onChange={(e) => setSumOfTransfer(e.target.value)}
              // required={true}
              sx={{ mb: 2 }}
              type={'number'}
              label={'Сумма перевода'}
            />
          </FormControl>
          <Button
            sx={{ marginBottom: 2 }}
            size='small'
            disabled={sumOfTransfer >= totalSum || totalSum <= 0}
            color='black'
            variant='contained'
            onClick={() => setSumOfTransfer(totalSum)}
          >
            Перевести все {editBalance(totalSum)}
          </Button>
          <FormControl fullWidth>
            <TextField
              onChange={(e) => setDescriptionOfTransfer(e.target.value)}
              label={'Описание'}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}
        >
          <Button
            disabled={isButtonDisabled}
            onClick={transferMoneyToAnotherInvoice}
            variant='contained'
            sx={{ marginRight: 1 }}
          >
            Перевести
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={closeTransferDialog}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
      {/* End - Перевод средств с одного счета на другой*/}
    </div>
  );
};
export default InvoiceKeeper;
